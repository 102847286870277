<template>
  <div id="app">
    <div>
      <b-navbar toggleable="lg" type="dark" variant="dark">
        <b-navbar-brand to="/">BI</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
          <b-navbar-nav class="ml-auto">
            <b-nav-item
                v-if="isAdminOrInRole(roles.marioBotAdmin)"
                :to="{ name: 'bots' }"
            >Mario Bots
            </b-nav-item>
            <b-nav-item
                v-if="isAdminOrInRole(roles.marioBotReportViewer)"
                :to="{ name: 'chat-history-summary' }"
            >Mario Report
            </b-nav-item>
            <b-nav-item
                v-if="isAdminOrInRole(roles.skypeBotAdmin)"
                :to="{ name: 'skype-bots' }"
            >Skype Bots
            </b-nav-item>

            <b-nav-item
                v-if="isAdminOrInRole(roles.userAdmin)"
                :to="{ name: 'users-management' }"
            >Settings
            </b-nav-item>
            <b-nav-item
                v-if="isAdminOrInRole(roles.superUser)"
                :to="{ name: 'audits' }"
            >Admin
            </b-nav-item>
            <b-nav-item-dropdown
                class="border border-secondary rounded ml-5"
                v-if="userInfo"
                right
            >
              <!-- Using 'button-content' slot -->
              <template v-slot:button-content>
                <b-icon-person-fill class="mr-2"/>
                <em>{{ userInfo.name }}</em>
              </template>
              <b-dropdown-item href="/identity/account/manage"
              >Manage
              </b-dropdown-item>
              <b-dropdown-item href="#" @click="Logout">
                <span>Logout</span>
              </b-dropdown-item>
            </b-nav-item-dropdown>
            <b-nav-item v-else href="/identity/account/login">Login</b-nav-item>
          </b-navbar-nav>
        </b-collapse>
      </b-navbar>
      <b-row class="mx-0">
        <b-col
            v-if="selectedModule"
            cols="3"
            xl="2"
            lg="3"
            class="sidenav__custom px-0"
        >
          <div v-if="isAdminOrInRole(selectedModule.roleNeeded)">
            <b-nav vertical>
              <div v-for="(link, index) in selectedModule.links" :key="index">
                <b-nav-item
                    v-if="link.routeName"
                    class="nav-item__custom"
                    :class="{
                                      'nav-item__custom__active':
                                        currentRouteName === link.routeName,
                                    }"
                    :to="{ name: link.routeName }"
                    @click="currentLink = link"
                >{{ link.title }}
                </b-nav-item>
                <b-nav-item v-else class="nav-item__custom" :href="link.href"
                >{{ link.title }}
                </b-nav-item>
              </div>
            </b-nav>
          </div>
        </b-col>
        <b-col class="px-5 py-3 content__custom">
          <router-view/>
          <!--padding for footer-->
          <section class="p-5"></section>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import axios from 'axios';

export default {
  name: 'app',
  data() {
    const roles = {
      marioBotAdmin: 'BotsAdmin',
      marioBotReportViewer: 'ChatHistoriesViewer',
      skypeBotAdmin: 'SkypeBotsAdmin',
      userAdmin: 'UsersAdmin',
      superUser: '',
    };
    return {
      roles: roles,
      modules: [
        {
          title: 'Mario Bots', module: 'mario-bots', roleNeeded: roles.marioBotAdmin,
          links: [
            {title: 'Bots', routeName: 'bots'},
            {title: 'Bot Settings', routeName: 'bot-settings'},
            {title: 'Bot Configurations', routeName: 'bot-configs'},
            {title: 'Client UI Settings', routeName: 'client-settings',},
            {title: 'System Messages', routeName: 'system-messages',},
            {title: 'Chat Rating', routeName: 'chat-rating',},
            {title: 'Pre Chat', routeName: 'pre-chat',},
            {title: 'Webhooks', routeName: 'webhooks',},
            {title: 'Intents', routeName: 'new-intents',},
            {title: 'Chat Filters', routeName: 'chat-filters',},
            {title: 'Train Chat Bot', routeName: 'train-bot',},
            {title: 'Audits', routeName: 'bot-audits',},
            {title: 'Intents (old)', href: '/intents',},]
        },
        {
          title: 'Mario Report', module: 'report', roleNeeded: roles.marioBotReportViewer, links: [
            {title: 'Active Chats', routeName: 'active-chats',},
            {title: 'Chat History', routeName: 'chat-history',},
            {title: 'Chat History Summary', routeName: 'chat-history-summary',},
            {title: 'Chat Rating Report', routeName: 'chat-rating-report',},
            {title: 'Chat Report', routeName: 'chat-report',},
            {title: 'Intent Summary', routeName: 'intent-summary',},
            {title: 'API Usages', routeName: 'api-usages',},
          ]
        },
        {
          title: 'Skype Bots', module: 'skype-bots', roleNeeded: roles.skypeBotAdmin, links: [
            {title: 'Skype Bots', routeName: 'skype-bots',},
            {title: 'Blacklist Management', routeName: 'skype-bot/anti-spam',},
            {title: 'System Messages', routeName: 'skype-bot/system-messages',},
            {title: 'Webhooks', routeName: 'skype-bot/webhooks',},
            {title: 'Intents', routeName: 'skype-bot/intents',},
            {title: 'Train Bots', routeName: 'skype-bot/train-bots',},
            {title: 'Chat History', routeName: 'skype-bot/chat-histories',},
            {title: 'API Usages', routeName: 'skype-bot/api-usages',},
          ]
        },
        {
          title: 'Settings', module: 'settings', roleNeeded: roles.userAdmin, links: [
            {title: 'Users Management', routeName: 'users-management',},
            {title: 'Trusted IPs', routeName: 'trusted-ip'},
          ]
        },
        {
          title: 'Admin', module: 'admin', roleNeeded: roles.superUser, links: [
            {title: 'Audits', routeName: 'audits'},
            {title: 'Application Configs', routeName: 'application-configs',},
            {title: 'Admin Bot Management', routeName: 'admin-bot-management',},
            
          ]
        },
      ],
    };
  },
  computed: {
    isPlainPage() {
      return this.$route ? this.$route.meta.isPlainPage : true;
    },
    moduleLinks() {
      return this.links.filter((link) => link.module === this.currentModule);
    },
    currentRouteName() {
      return this.$route.name;
    },
    userInfo() {
      return this.$store.state.userInfo;
    },
    currentPageTitle() {
      return this.$store.state.currentPageTitle;
    },
    currentModule() {
      return this.$store.state.currentModule;
    },
    selectedModule() {
      return _.find(this.modules, {module: this.currentModule});
    },
    currentModuleTitle() {
      return this.selectedModule?.title;
    }
  },
  methods: {
    // e.g. isInRole(roles.marioBotAdmin)
    isAdminOrInRole(role) {
      const userInfo = this.userInfo;
      return userInfo.isAdmin || userInfo.roles.includes(role);
    },
    linkSelected() {

    },
    Logout() {
      this.$store.commit('resetStates');
      axios.post('/identity/account/logout').then(function () {
        window.location.href = '/identity/account/login?ReturnUrl=%2F';
      });
    },
  },
};
</script>

<style scoped>
.nav_bg {
  background-color: #f1f1f1;
}

.nav-item__custom a {
  background-color: #f1f1f1;;
  color: #212529 !important;
}

.nav-item__custom__active a {
  background-color: #fefefe;
}

.nav-item__custom:hover a {
  background-color: #fefefe;
  font-weight: bold;
}

.page-title {
  font-size: 28px;
  font-weight: bold;
}
</style>

<style>
body {
  background-color: #fefefe;
}

#app {
  height: 100vh;
  overflow-y: hidden;
}

.sidenav__custom {
  background-color: #f1f1f1;
  height: 100vh;
  overflow-y: auto;
}

.content__custom {
  height: 100vh;
  overflow: auto;
}
</style>
